import {
  domContentLoaded,
  makeSwarms,
  startSwarms,
} from '../swarm';

import './404.css';

const background = document.getElementById('background');

const load = () => background.removeAttribute('unloaded');
const loadDeferred = () => setTimeout(load, 0);

const swarms = makeSwarms([
  { x: 50, y: 50, segments: [
    { size: 700, population: 350, clustering: -3 },
    { size: 600, population: 250, clustering: -3 },
  ] },
  { x: 25, y: 50, segments: [
    { size: 700, population: 350, clustering: -3 },
    { size: 600, population: 150, clustering: -3 },
  ] },
  { x: 75, y: 50, segments: [
    { size: 700, population: 350, clustering: -3 },
    { size: 600, population: 250, clustering: -3 },
  ] },
]);

Promise.all([domContentLoaded, swarms])
.then(([_, swarms]) => {
  swarms.forEach(swarm => background.appendChild(swarm));
})
.then(startSwarms)
.then(loadDeferred)
.catch(e => console.error("Yikes, can't start", e));
